:root{
    --background: #304584;

    --content: #fff;
    --cyan: #eeecec;

    --gray: #F3F5F8;
    --gray-100: #e1e1e6;
    --gray-300: #a8a8b3;
    --gray-400: rgb(148 163 184);
    --gray-800: #29292e;
    --gray-850: #1f2729;
    --gray-900: #121214;


    /*========== Font and typography ==========*/
    --body-font: 'Lato', sans-serif;
    --biggest-font-size: 2.5rem;
    --h1-font-size: 3rem;
    --h2-font-size: 2.25rem;
    --h3-font-size: 1.8rem;
    --h4-font-size: 1.2rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    /*========== Font weight ==========*/
    --font-light: 100;
    --font-medium: 400;
    --font-semi-bold: 600;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


@media (max-width: 1280px){
    html{
        font-size: 93.75%;
    }
}

@media (max-width: 720px){
    html{
        font-size: 87.5%;
    }
}

@media (max-width: 601px){
    html{
        font-size: 82%;
    }
}

@media (max-width: 480px){
    html{
        font-size: 70%;
    }

    img{
        max-width: 90%;
        height: auto;
    }
}

body, input, textarea, button {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

body{
    background-color: var(--gray) !important;
    -webkit-font-smoothing: antialiased;
}

.btn{
    border-radius: 0 !important;
}

.navbar-collapse {
    flex-grow: 0 !important;
}

.nav-link {
    text-decoration: none  !important;
    color: var(--gray-850) !important;
    font-size: 1rem !important;
    font-weight: 400  !important;
    padding: 0 12px !important;
    border-bottom: 2px solid transparent !important;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s  !important;
}

.nav-link:hover {
    border-bottom: 2px solid var(--gray-900) !important;
}

.form-label {
    margin-top: 0.5rem;
}

table.dataTable{
    width: 100% !important;
}

table, th, tr {
    border: none !important;
    border-collapse: collapse !important;
}

tbody tr{
    height: 30px;
    background-color: #fff !important;
    transition: .3s ease;
}

td, th{
    color: var(--gray-800);
    padding: 12px 0;
    border-bottom: solid var(--cyan) 6px !important;
}

tbody tr:hover{
    background-color: rgb(148 163 184) !important;
}

.content{
    background-color: var(--content);
    padding: 8px 12px;
    margin: 8px 0;
}

.title{
    color: var(--background);
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
}

.title_sub{
    color: var(--background);
    font-size: 1.6rem !important;
    font-weight: 400;
    margin: 4px 0 !important;
    font-family: 'Lato', sans-serif;
}

.modal{
    font-size: 12px;
}

footer{
    position: relative;
    bottom: 0;
    margin-top: 30px;
    padding: 20px;
    width: 100%;
    background-color: #2F4483;
    color: #e1e1e6 !important;
    font-size: .85rem;
    font-family: 'Lato', sans-serif;
}

.title__plans{
    color: #2773a5;
    font-size: var(--h3-font-size);
    font-weight: var(--font-semi-bold);
}

.title__plans i{
    color: var(--gray-850) !important;
    font-size: var(--h4-font-size) !important;
    font-weight: var(--font-light) !important;
}


.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #2773a5;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.animate-bottom {
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 }
    to { bottom:0px; opacity:1 }
}

@keyframes animatebottom {
    from{ bottom:-100px; opacity:0 }
    to{ bottom:0; opacity:1 }
}


.bg-body{
    height: 100vh;
    background: rgb(34,92,142);
    background: linear-gradient(180deg, rgba(34,92,142,1) 37%, rgba(192,50,53,1) 78%);
}

.card__site{
    padding: 3rem 0;
    margin-top: 10%;
    background: rgba( 255, 255, 255, 0.1 );
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 10px;
    border:none;
    box-shadow: 0 8px 32px 0 rgba(34,92,142,1);
}
